import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import _ from "lodash"
import usePagination from "../../hooks/usePagination"
import "./NewsTab.scss"
import InnerPagination from "../InnerPagination/InnerPagination"
import NewsCard from "../NewsCard/NewsCard"

const NewsTab = ({ data, blogCategory }) => {
  const newsFilters = [{ value: "all-news", label: "All News" }]
  const [filter, setFilter] = useState("all-news")

  blogCategory?.forEach((element) => {
    if (element.node.blog_name) {
      newsFilters.push({
        label: element.node.blog_name,
        value: element.node.slug,
      })
    }
  })

  const selectOptions = [
    { value: newsFilters[0].value, label: newsFilters[0].label },
  ]
  const [filterOptions, setFilterOptions] = useState(selectOptions)
  const [filteredList, setFilteredList] = useState(data)
  const [set, setClass] = useState(false)
  let [projects, setProjects] = useState(data)

  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: filteredList,
    itemsPerPage,
  })
  // for pagination

  const newClass = (event) => {
    const parent = document.getElementById("filter-link")
    parent.classList.add("filter-active")
    setClass(false)
    const allChildElements = parent.querySelectorAll(".nav-link")
    for (const box of allChildElements) {
      box.classList.remove("active")
    }
    event.target.classList.add("active")
  }

  const applyCategory = (filt) => {
    if (filt !== "all-news") {
      const filtered = _.filter(data, function (o) {
        if (o.node.categories?.length > 0) {
          return o.node.categories?.some((item) => item.slug === filt)
        }
      })

      setProjects(filtered)
      setFilteredList(filtered)
    } else {
      setProjects(data)
      setFilteredList(data)
    }
    setCurrentPage(1)
  }

  useEffect(() => {
    newsFilters.slice(1, 100).map((item, i) => {
      _.filter(data, function (o) {
        if (o.node.categories?.length > 0) {
          o.node.categories?.some((blog) => {
            if (blog.slug === item.value) {
              selectOptions.push({ value: item.value, label: item.label })
            }
          })
        }
      })
    })

    setFilterOptions(_.uniqBy(selectOptions, (e) => e.value))
  }, [filteredList])

  return (
    <div className="news-page-wrapper">
      <Container>
        <div className="news-top-sections">
          <div className="categories-container">
            <div className="categories-list">
              <Select
                options={filterOptions}
                isSearchable={false}
                onChange={(e) => {
                  setFilter(e.value)
                  applyCategory(e.value)
                }}
                placeholder="All News"
                className="select-control"
                classNamePrefix="react-select"
                // styles={customStylesNews}
                components={{
                  DropdownIndicator: () => (
                    <i className="icon down-arrow-light"></i>
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <ul className="inner-tab nav-tabs" id="filter-link">
              <li className="nav-item">
                <button
                  type="button"
                  className="nav-link active tab_fill"
                  onClick={(event) => {
                    setFilter("all-news")
                    applyCategory("all-news")
                    newClass(event)
                  }}
                >
                  All News
                </button>
              </li>
              {filterOptions.slice(1, 20).map((option) => (
                <li className="nav-item" key={option.value}>
                  <button
                    type="button"
                    className={`nav-link ${option.value}`}
                    onClick={(event) => {
                      setFilter(option.value)
                      applyCategory(option.value)
                      newClass(event)
                    }}
                  >
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>

      <div className="news_card-listing-wrapper">
        {currentItems?.length > 0 && (
          <NewsCard blogData={currentItems.slice(0, currentItems?.length)} />
        )}

        <Container>
          <InnerPagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredList.length}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </div>
    </div>
  )
}

export default NewsTab
