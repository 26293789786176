import { graphql } from "gatsby"
import React from "react"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import ListingIntroModule from "../components/ListingIntroModule/ListingIntroModule"
import NewsSliderCard2 from "../components/NewsSliderCard1/NewsSliderCard2"
import NewsTab from "../components/NewsTab/NewsTab"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsLandingTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const allStrapiBlogWithDate = data?.allStrapiBlogWithDate?.edges;
  const allStrapiBlogNullDate = data?.allStrapiBlogNullDate?.edges;
  const blogCategory = data?.allStrapiBlogCategory?.edges;
  const blogData = [...allStrapiBlogWithDate, ...allStrapiBlogNullDate]
  return (
    <Layout>
      <div className="layout-padding-top">
        <BreadcrumbModule
          subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
          subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
          title={PageData.title}
          parentlabel={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title
          }
          parentlink={
            PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug
          }
        />
        <div className="white-bg">
          {PageData?.add_page_modules?.length > 0 &&
            PageData.add_page_modules?.map((module) => {
              return (
                <div key={module?.id}>
                  {module?.__typename ===
                    "STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT" && (
                    <ListingIntroModule
                      content={module.content}
                      headingTitle={
                        module.title ? module.title : PageData.title
                      }
                    />
                  )}

                </div>
              )
            })}

          <NewsTab data={blogData} blogCategory={blogCategory} />
          {PageData?.add_page_modules?.length > 0 &&
            PageData.add_page_modules?.map((module) => {
              return (
                <div key={module?.id}>
                 
                                {module?.strapi_component === "components.blogs" && (
                <NewsSliderCard2 blogData={module?.blogs} pageData={module} />
              )}

                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default NewsLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          __typename
          ...PlainContentFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_BLOGS {
          id
          strapi_component
          title
          blogs {
            title
            publish
            strapi_id
            slug
            publish
            tile_image {
              url
            }
            date
          }
        }
      }
    }

    allStrapiBlogCategory {
      edges {
        node {
          blog_name
          slug
          strapi_id
        }
      }
    }

    allStrapiBlogWithDate: allStrapiBlog(
      filter: {publish: {eq: true}, date: {ne: null}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          ...BlogFragment
        }
      }
    }

    allStrapiBlogNullDate: allStrapiBlog(
      filter: {publish: {eq: true}, date: {eq: null}}
    ) {
      edges {
        node {
          ...BlogFragment
        }
      }
    }
  }
`;