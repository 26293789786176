import { Link } from "gatsby"
import React from "react"
import dateFormat from "dateformat"
import { Container } from "react-bootstrap"
import "./NewsCard.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const NewsCard = ({ blogData }) => {
  const imagename = "blog.tile_image.small_image"
  return (
    <div className="news-card-wrapper">
      <Container>
        <div className="news-card-sections">
          {blogData?.map((data) => {
            let processedImages = ""
            processedImages =
              data?.node?.imagetransforms?.tile_image_Transforms ||
              JSON.stringify({})
            return (
              <div className="news-card-module" key={data?.node?.strapi_id}>
                <div className="image-section">
                  <Link to={`${data?.node?.slug}/`}>
                    <ImageModule
                      ImageSrc={data.node?.tile_image}
                      altText={`${data.node?.title}`}
                      imagetransforms={processedImages}
                      renderer="srcSet"
                      imagename={imagename}
                      strapi_id={data.node?.strapi_id}
                      classNames="img-card"
                    />
                  </Link>
                </div>
                <div className="news-info">
                  <Link to={`${data?.node?.slug}/`}>
                    <h4>{data?.node?.title}</h4>
                  </Link>
                  <p>{data?.node?.date != null && dateFormat(data?.node?.date, "dd mmmm yy")}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default NewsCard
